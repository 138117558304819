@use '@tsp-ui/core/sass/utils';

.title {
  text-align: center;
  width: 100%;
}

.dialogPaper {
  width: 500px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: utils.spacing(3);
  padding-top: utils.spacing(1.5) !important;
}

.iconContainer {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.pending {
    animation: breathing 5s ease-out infinite normal;
    background-color: rgb(237 108 2 / 10%);
  }

  &.error {
    background-color: rgb(211 47 47 / 10%);
  }

  &.success {
    background-color: rgb(46 125 50 / 10%);
  }
}

.progressContainer {
  align-self: stretch;
}

.progress {
  height: utils.spacing(1);
  border-radius: var(--app-border-radius);
}

.importStatusText {
  margin-top: utils.spacing(0.5);
}

.actions {
  justify-content: center;
}

.pleaseCloseMessage {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: utils.spacing(1);
}

.onceClosed {
  margin-top: utils.spacing(2);
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}
