@use '@tsp-ui/core/sass/utils';

.root {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(3);
  display: flex;
  gap: utils.spacing(3);
}

.numPrograms {
  position: relative;
  padding: utils.spacing(2, 3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: utils.spacing(1);
  margin-left: auto;
  overflow: hidden;
}

.filterHeader {
  margin-bottom: utils.spacing(0.5);
  margin-top: utils.spacing(-1);
}

.loanData {
  padding: utils.spacing(1);
  display: flex;
  gap: utils.spacing(1);
  flex-direction: column;
}

.demographics {
  padding: utils.spacing(1);
  display: flex;
  gap: utils.spacing(1);
}

.demographicsIcons {
  display: flex;
  gap: utils.spacing(1);
  flex-direction: column;
}

.borderSlug {
  border-right: 1px solid var(--app-color_border);
  align-self: stretch;
  width: 1px;
  margin-inline: utils.spacing(1);
}

.checkboxForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.yesNoButtons {
  display: flex;
  justify-content: flex-end;
  gap: utils.spacing(1);
  margin-top: utils.spacing(1);
}

.checkboxFormContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: utils.spacing(1);
}

.list {
  margin: utils.spacing(1);
  padding-left: utils.spacing(2);
}

.editButton {
  margin-block: utils.spacing(-0.5);
}

.availableLocationContainer {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
}
