@use '@tsp-ui/core/sass/utils';

.iconTableCell {
  width: 1px;
  white-space: nowrap;
}

.statusIconTableCell {
  text-align: center;

  & > svg {
    margin-top: utils.spacing(0.5);
  }
}

.iconTableCellButtons {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  padding-block: utils.spacing(0.5);
  position: relative;
}

.selectButtonContainer {
  position: relative;
  border-radius: var(--app-border-radius);
  overflow: hidden;
}

.maxAmountNote {
  width: 300px;
}

.importConfirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.spacing(2);
}

.tooltip {
  border-radius: var(--app-border-radius);
  background: #FFF;
  color: var(--app-color_text);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2),
      0 1px 1px 0 rgba(0,0,0,0.14),
      0 1px 3px 0 rgba(0,0,0,0.12);
}

.tooltipBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.spacing(1);
}

.originalValueLabeledField {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.assistanceAmountContainer {
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
}
