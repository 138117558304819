@use '@tsp-ui/core/sass/utils';

.dialogPaper {
  background-color: var(--app-color_background);
}

.table {
  padding: 0;
  border: 0;
}

.switch {
  margin-right: 0;
}

.selectRoot {
  margin-block: utils.spacing(0.5);
}

.select {
  padding-top: utils.spacing(1);
}

.alertBanner {
  margin-bottom: utils.spacing(2);
  display: flex;
  justify-content: center;
}