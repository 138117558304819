@use '@tsp-ui/core/sass/utils';

.main {
  max-width: 600px;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.spacing(2);
}
