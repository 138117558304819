@use '@tsp-ui/core/sass/utils';

.locationPopoverList {
  padding: 0;
}

.locationPopoverPaper {
  padding: utils.spacing(1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.menuItem {
  padding: utils.spacing(1);
  text-align: left;
  border-radius: var(--app-border-radius);
  overflow: hidden;
}

.divider {
  margin: 0 !important;
}
