@use '@tsp-ui/core/sass/utils';

.tableRoot {
  margin-bottom: utils.spacing(3);
}

.tableRoot td:not(:first-child) {
  text-align: center;
}

.noConfigs thead tr th {
  text-align: center;
}

.infoIconWrapper {
  justify-content: center;
}

.infoIcon {
  padding-bottom: utils.spacing(0.5);
}

.alert {
  margin-bottom: utils.spacing(2);
  max-width: 500px;
}