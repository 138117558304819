@use '@tsp-ui/core/sass/utils';

.main {
  grid-area: main;
  margin: auto;
  padding: utils.spacing(0, 6, 6, 6);
  max-width: 1366px;
  width: 100%;
  height: 100%;
}

.toolbar {
  display: flex;
  align-items: center;
  gap: utils.spacing(3);
  border-bottom: 1px solid var(--app-color_border);
  margin-bottom: utils.spacing(2);
  margin-inline: utils.spacing(-1);
  padding-inline: utils.spacing(1);
}

.logo {
  margin-block: utils.spacing(2);
  height: 60px;
}

.headerActionsContainer {
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
  position: absolute;
  right: 0;
}

.pageMessageContainer {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.main.centered {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .toolbar {
    align-self: stretch;
  }

  & .header {
    width: 100%;
  }

  & .pageMessageContainer {
    position: absolute;
  }

  & .headerActionsContainer {
    position: absolute;
    right: 0;
  }
}

.loader {
  align-self: stretch;
  margin-top: utils.spacing(-2);
  margin-inline: utils.spacing(-1);
  padding-inline: utils.spacing(1);
  position: relative;
  top: -1px;
}
