@use '@tsp-ui/core/sass/utils';

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);

  a {
    color: var(--app-color_primary);
  }
}

.dialogPaper {
  background-color: var(--app-color_background);
}

:global(.program-details) {
  border-collapse: collapse;
  width: 100%;

  th, td {
    text-align: start;
    vertical-align: top;
    border-bottom: 1px solid var(--app-color_border);
    padding: utils.spacing(1, 2);
  }

  th {
    border-right: 1px solid var(--app-color_border);
    width: 220px;
    color: var(--app-color_text-secondary);
    font-weight: 500;
  }

  td {
    word-break: break-word;
  }

  tbody tr:last-child th, tr:last-child td {
    border-bottom: 0;
  }

  p {
    margin: 0;
  }

  p:not(:first-of-type) {
    margin-top: utils.spacing(1);
  }

  ul {
    margin: 0;
    padding-left: utils.spacing(2);

    &:not(:first-child) {
      margin: utils.spacing(1);
    }
  }

  strong, b {
    font-weight: 500;
  }

  :global(.program-alert) {
    padding: utils.spacing(1);
    border: 1px solid #ed6c02;
    border-radius: var(--app-border-radius);
    background-color: rgb(255, 244, 229);
    text-align: center;

    &:before {
      content: '';
      display: block;
      width: 36px;
      height: 36px;
      margin-inline: auto;
      margin-bottom: utils.spacing(1);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ED6C02'%3E%3Cpath d='M12 5.99 19.53 19H4.47L12 5.99M12 2 1 21h22L12 2z'%3E%3C/path%3E%3Cpath d='M13 16h-2v2h2zm0-6h-2v5h2z'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  :global(.funding-status) :global(.status-inactive) {
    font-weight: 500;
    color: var(--app-color_error);
  }

  :global(.funding-status) :global(.status-suspended) {
      font-weight: 500;
      color: var(--app-color_info);
    }

  :global(.postclose-delivery-address) {
    width: fit-content;
    padding: utils.spacing(0.5, 1);
    border: 1px solid var(--app-color_border);
    border-radius: var(--app-border-radius);
    margin-left: utils.spacing(1);
  }

  :global(.postclose-delivery-instructions) {
    margin-left: utils.spacing(1);
  }

  :global(.postclose-recipient:not(:first-of-type)) {
    margin-top: utils.spacing(1);
  }

  :global(table.income-limits) {
    border: 1px solid var(--app-color_border);
    border-radius: var(--app-border-radius);
    border-spacing: 0;
    overflow: hidden;

    thead th {
      width: auto;
      border-bottom: 1px solid var(--app-color_border);
      background-color: var(--app-color_background);

      &:last-child {
        border-right: 0;
      }
    }
  }

  :global(.columns) {
    & > tbody > tr {
      & > td {
        padding-block: 0;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        & > div {
          padding-left: utils.spacing(1);
          text-indent: utils.spacing(-1);
        }
      }
    }
  }
}

:global(#section_revision_history) > tbody > tr > td {
  padding: 0;
}

:global(#program-change-table) {
  border-spacing: 0;
  width: 100%;

  th {
    width: auto;
    border-bottom: 1px solid var(--app-color_border);
    background-color: var(--app-color_background);

    &:last-child {
      border-right: 0;
    }
  }

  tbody tr:not(:last-child) td {
    border-bottom: 1px solid var(--app-color_border);
  }

  td:first-child {
    font-weight: 500;
    color: var(--app-color_info);
  }

  td:nth-child(2) {
    white-space: nowrap;
  }
}

.footer {
  margin-block: utils.spacing(1);
}
