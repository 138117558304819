@use '@tsp-ui/core/sass/utils';

.dialogPaper {
  background-color: var(--app-color_background);
}

.content {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  align-items: center;
}

.combinedAssistanceList {
  list-style-type: none;
  text-align: center;
  align-self: center;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.list {
  align-self: center;
  padding-left: 0;
  margin-block: utils.spacing(2);

  & > li {
    font-weight: 500;
    width: 400px;

    & > * {
      font-weight: 400;
    }
  }

  ul {
    padding-left: utils.spacing(3);
    margin-top: utils.spacing(-1);
  }
}

.paper {
  padding: utils.spacing(1, 2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  align-items: flex-start;
  margin-bottom: utils.spacing(2);

  & > img {
    width: 100%
  }
}

.noSetupRequired {
  margin-block: utils.spacing(2);
}

.additionalSetup {
  margin-bottom: utils.spacing(2);
  flex-direction: column;
}

.grantAdditionalSetup {
  margin-top: utils.spacing(2);
}

.featureImg {
  border-radius: var(--app-border-radius);
  width: 100%;
  margin-block: utils.spacing(2);
}
