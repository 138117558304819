@use '@tsp-ui/core/sass/utils';

.programDisplay {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(0.5, 1.5);
}

.programWrapper {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
}

.centeredContainer {
  display: flex;
  justify-content: center;
}

.invalidAddress {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(3);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
  align-items: center;
}

.tableSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: utils.spacing(3, 2, 1, 2);
  padding-inline: utils.spacing(1);
  border-bottom: 1px solid var(--app-color_border);
}

.tableContainer {
  padding-inline: utils.spacing(3);
  border: 0;
}

.selectedProgramContainer {
  padding: utils.spacing(1, 3, 1, 3);
  margin-top: utils.spacing(3);
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
}

.selectedProgram {
  padding: utils.spacing(1);
}

.selectedTableContainer {
  border: 0;
  padding: 0;
  background-color: var(--app-color_background-medium);
}

.selectedTableContainer table > tbody > tr > td {
  border: 0;
}

.selectedTableSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: utils.spacing(1);
  margin-inline: utils.spacing(-1);
  padding-inline: utils.spacing(1);
  border-bottom: 1px solid var(--app-color_border);
}

.noneSelectedHeader {
  border-bottom-width: 0;
  margin-bottom: 0;
}

.locationSelectionContainer {
  margin-top: utils.spacing(3);
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(3);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
  align-items: center;
}

.locationsPreview {
  display: flex;
  gap: utils.spacing(2);
}

.locationPreviewCard {
  overflow: hidden;

  &:hover, &:has(:focus) {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2),
        0 1px 1px 0 rgba(0,0,0,0.14),
        0 1px 3px 0 rgba(0,0,0,0.12);
  }
}

.locationPreviewButton {
  padding: utils.spacing(1);
  text-align: left;
  border-radius: var(--app-border-radius);
  overflow: hidden;
  height: 100%;
}
